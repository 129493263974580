<template>
  <div class="enwx-login">
    <div class="logo-box">
      <img src="../../assets/logo.png" alt="" />
    </div>
    <div class="login-box">
      <div id="ww_login"></div>
    </div>
  </div>
</template>

<script>
// 企微登录扫码页
// import * as ww from '@wecom/jssdk'

export default {
  data() {
    return {

    }
  },
  mounted() {
    let that = this;
    const script = document.createElement('script');
    script.src = 'https://wwcdn.weixin.qq.com/node/open/js/wecom-jssdk-2.0.2.js'; // 替换为你的js文件路径
    script.onload = () => {
      // 脚本加载完毕后的操作
      console.log('加载完js---', ww)
      that.getWxcpParam()
    };
    document.head.appendChild(script);


  },
  methods: {
    //获取企微登录授权参数
    getWxcpParam() {
      let that = this;
      that.request({
        method: 'post',
        url: '/wxCp/getWxCpLoginParams',
        param: {},
        success: function (res) {
          if (res && res.result) {
            let param = Object.assign({}, res.result)
            that.createWxcpLoginPanel(param)
          } else {

            that.$message.error("授权参数失败，" + res)
          }
        },
        error: function (error) {
          //服务器异常

          that.$message.error('授权参数异常。',);
        }
      })
    },

    //初始化登录组件
    createWxcpLoginPanel(params) {
      const wwLogin = ww.createWWLoginPanel({
        el: '#ww_login',
        params: params,
        onCheckWeComLogin({ isWeComLogin }) {
          console.log(isWeComLogin)
        },
        onLoginSuccess({ code }) {
          console.log({ code })
        },
        onLoginFail(err) {
          console.log(err)
        },
      })
    },

  }
}
</script>
<style lang="scss" scope>
.enwx-login {
  padding: 40px 40px 0 40px;
  position: relative;
  height: 100%;
  .logo-box {
    height: 34px;
  }
  .login-box {
    width: 500px;
    height: 440px;
    margin: 0 auto;
    background: #ffffff;
    // box-shadow: 0px 0px 23px 0px rgba(8, 144, 206, 0.23);
    // border-radius: 29px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -260px;
  }
}
</style>